import { Box, Button, ButtonGroup, Grid, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Page from "./Page";
import { useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Apple, LaptopWindowsRounded, WindowSharp } from "@mui/icons-material";


const RFToggleButton = styled(ToggleButton)(({ theme }) => ({
    color: '#000000',
    backgroundColor: 'none',
    border: 'none',
    fontSize: '0.9rem',
    textTransform: 'none',
    padding: '0.3rem 1rem 0.3rem 1rem',
    "&.MuiToggleButton-root":{
        color: '#ffffffcc',
        border: '1px solid #ffffff33',
    },
    "&.MuiToggleButton-root:hover":{
        color: '#ffffff',
        backgroundColor: '#ffffff22',
    },
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "ffffffcc",
        backgroundColor: '#ffffff33'
      }
  }));

export default function Support() {
    const [os, setOs] = useState('windows')
    const [sim, setSim] = useState('fs2020')
    const [localWorks, setLocalWorks] = useState('none')
    const [remoteWorks, setRemoteWorks] = useState('none')

    function handleOsChange(e, value) {
        if (value === null) return
        setOs(value)
        if (value === 'macos') setSim('xplane')
    }
    function handleSimChange(e, value) {
        if (value === null) return
        if (value !== 'xplane' && os === 'macos') {
            setOs('windows')
        }
        setSim(value)
    }
    function handleLocal(e, value) {
        setLocalWorks(value)
        setRemoteWorks('none')
    }
    function handleRemote(e, value) {
        setRemoteWorks(value)
    }

    return (
        <Page>
            <Grid container columns={12} spacing={2} justifyContent='flex-start'>
                <Grid item xs={12} sm={12} md={12}>
                    <Box className='productDetailTile'>
                        <Stack direction='column' spacing={2} alignItems={{md:'flex-start', sm:'center'}} justifyContent='space-between'>
                        <h2 className="tileHeading">Installation and connection guide</h2>
                            <Stack direction={{md:'row', sm:'column'}}  spacing={2} alignItems='center'>
                                
                                <ToggleButtonGroup size='small' exclusive value={os} onChange={handleOsChange}>
                                    <RFToggleButton value='windows'><Stack direction='row' spacing={1}><WindowSharp /><span>Windows</span></Stack></RFToggleButton>
                                    <RFToggleButton value='macos'><Stack direction='row' spacing={1}><Apple /><span>MacOS</span></Stack></RFToggleButton>
                                </ToggleButtonGroup>


                                <ToggleButtonGroup size='small' exclusive value={sim} onChange={handleSimChange}>
                                    <RFToggleButton value='fs2020'>MSFS 2020/2024</RFToggleButton>
                                    <RFToggleButton value='fsx'>FSX/P3D</RFToggleButton>
                                    <RFToggleButton value='xplane'>X-Plane</RFToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                        </Stack>

                        <Stack direction='column' spacing={1} sx={{paddingTop:'1rem', marginBottom: '2rem'}}>
                            <InstallContent sim={sim} os={os}/>
                        </Stack>

                        <Stack direction='column' spacing={1} sx={{paddingTop:'1rem', marginTop:'2rem',marginBottom: '2rem', borderTop:'1px solid #ffffff33'}}>
                            <RunContent sim={sim} os={os}/>
                        </Stack>

                        <Stack direction='column' spacing={1} sx={{paddingTop:'1rem', marginTop:'2rem',marginBottom: '2rem', borderTop:'1px solid #ffffff33'}}>
                            <ConnectContent sim={sim} os={os}/>
                        </Stack>
                    </Box>

                    <Box className='contact'>
                        <h4 className='productPageSubHeading'>Debugging conection problems</h4> 
                        <Box className='compatibilityTile'>
                            <p>For most users, devices will connect to RemoteFlight Server or plugin on the first attempt. If this isn't your case, don't panic!</p>
                            <ol className='docsList'>
                                <li>First you should verify that the server or plugin is actually correctly installed and connected to your simulator. Try to access the server using the browser on the same machine as your simulator. Simply use your browser and type in your IP address and port like this: <pre>http://IP_ADRESS:PORT</pre>. For example <pre>http://192.168.0.200:3385</pre></li>
                                <li>You should see a test page that looks like this<br/>
                                    <RFTestResult /><br/>
                                    <ToggleButtonGroup style={{marginBottom:'1rem'}} size='small' value={localWorks} exclusive onChange={handleLocal}><RFToggleButton value='yes'>Yes, I see this page</RFToggleButton><RFToggleButton value='no'>Nope, nothing...</RFToggleButton></ToggleButtonGroup>
                                </li>
                                
                                {localWorks === 'no' ? 
                                    <li>In this case you need to check correct installation of FSUIPC, XPUIPC or the native plugin for Mac.</li> : null
                                }
                                {localWorks === 'yes' ? 
                                    <>
                                    <li>
                                        You can continue by using the same method as above, but this time using a browser on your iPhone or iPad, or any other computer in your local network.</li> 
                                        
                                        <li>You should now see the test page on your iPhone or iPad. Did it work?<br/><ToggleButtonGroup style={{marginBottom:'1rem', marginTop:'0.5rem'}} size='small' value={remoteWorks} exclusive onChange={handleRemote}><RFToggleButton value='yes'>Yes, I see this page</RFToggleButton><RFToggleButton value='no'>Nope, nothing</RFToggleButton></ToggleButtonGroup></li>
                                        </>    : null
                                }
                                {remoteWorks === 'yes' ?
                                    <li>At this point, you should be able to connect from RemoteFlight apps.<br/>If you can't, please check the settings in the app and make sure you have the correct IP address and port number.</li> :
                                    null
                                }
                                {remoteWorks === 'no' ? 
                                    <li>You need to check your network settings and hardware, as the server machine isn't accessible from other devices.                                        
                                    </li> : null    
                                }
                            </ol>
                            {remoteWorks === 'no' ? 
                                <>
                                    <h4>Here are some tips:</h4>
                                    <ul className='docsList'>
                                        <li>Make sure your firewall allows connections to RemoteFlight Server</li>
                                        <li>Using VPN clients or Hamachi clients will prevent RemoteFlight Server to be accessible</li>
                                        <li>If you have multiple network interfaces, make sure that you have the right IP adress for the one actually using the network connection to the Wifi router</li>
                                        <li>Simply restarting the router has been reported to help on multiple occasions</li>
                                        <li>some routers have "wifi isolation" mode, that isolates devices from each other - make sure you haven't got such feature switched on</li>
                                        <li>Important note on the firewall setup: If your home network is configured as Public, make sure you allow connections to RemoteFlight server on Public networks as well.</li>
                                        <li>Know your network and other devices in it. Try disconnecting some devices, thus narrowing down possible issues. For example, one user has solved his connection problems by disconnecting an incorrectly configured network printer.</li>
                                    </ul>
                                </> : null}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Page>
    )
}

export function InstallContent({sim, os}){

    let fsuipc = (
        <li>Download and install FSUIPC library for your version of the Flight Simulator
            <ul>
                <li>If you have multiple versions (e.g. standalone and Steam), FSUIPC installer will ask which version you want to install it to.</li>
                <li>Free unregistered version of FSUIPC is sufficient, you don't have to buy it (but it is encouraged if you wish to use advanced features).</li>
            </ul>
        </li>
    )
    if (sim === 'xplane') {
        fsuipc = (
            <li>Download and install XPUIPC plugin
            <li>Install XPUIPC into your X-Plane as per the instructions provided</li>
        </li>
        )
    }

    if (os === 'windows') {
        return (
            <>
                <h4 className='supportSubHeading'>Installation</h4>
                <ol className='docsList'>
                    <li>Make sure you have Microsoft .NET Framework 4. (Windows 7 and above have .NET already installed)</li>
                    {fsuipc}
                    
                    <li>Download RemoteFlight Server
                        <ul>
                            <li>Unzip the entire content to a folder on desktop or folder of your choice, installation is not required</li>
                        </ul>
                    </li>
                </ol>
            </>
        )
    } else if (os === 'macos') {
        return (
            <>
            <h4 className='supportSubHeading'>Installation</h4>
            <ol className='docsList'>
                <li>Download the plugin file from <Link to='/downloads'>Download page</Link></li>
                <li>Inside is a folder named <strong>"rfxpl"</strong></li>
                <li>Unzip this entire folder to your <strong>XPLANE_DIR/Resources/Plugins/</strong></li>
                <li>Resulting structure should be <strong>XPLANE_DIR/Resources/Plugins/rfxpl/...</strong></li>
                <li>You may check out the readme.txt file for further details</li>
                <li>To connect, you will need to obtain your Mac IP address on local network. The easiest way is to look in System Preferences / Network</li>
                <li>Default port number is set to <strong>1234</strong>, if you need to change it, edit file 'rfxpl.ini' in plugin folder</li>
                <li>If you use MacOS Catalina or later, disable the Gatekeeper
                    <ul>
                        <li>Easiest way to work around this is to disable this feature by opening the Terminal and running the following command:
<pre>sudo spctl --master-disable</pre></li>
                    </ul>
                </li>
                <li>Launch X-Plane and start a flight</li>
                <li>Open the top menu bar, find Plugins, RemoteFlight and Start server</li>
            </ol>
            </>
        )
    }
}

export function RunContent({sim, os}) {
    if (os === 'windows' && sim !== 'xplane') {
        return (
            <>
                <h4 className='supportSubHeading'>Running the server</h4>
                <ol className='docsList'>
                    {sim === 'fs2020' ? 
                    <>
                        <li>Start FSUIPC and make sure it is running</li>
                        <li>Start Microsoft Flight Simulator 2020</li></>:
                    <>
                        <li>Start the simulator (FSUIPC will start automatically in background)</li>
                    </>
                    }
                    
                    <li>Start RemoteFlight Server as administrator
                        <ul>
                            <li>On first run, the server will choose a random port, which you can randomize further or set manually. When you are happy with the port number, click the <strong>Start server</strong> button.</li>
                            <li>On first run a pop-up window will appear, requesting access to firewall if you have one enabled. Allow this exception so that RemoteFlight Server can be accessed via network.</li>
                            <li>If you are using any 3rd party firewall application, make sure it allows RemoteFlight Server (please consult the manual for your firewall on how to do this). I did testing with Norton and Avast firewall, both work without problems if properly set up.</li>
                            <li>Important note on the firewall setup: If your home network is configured as Public, make sure you allow connections to RemoteFlight server on Public networks.</li>
                            <li>Upon successful connection the status message in server window will change to <strong>Connected to FS</strong> and a message will appear in Flight Simulator.</li>
                        </ul>
                    </li>
                    <li>Your system is now ready to be controlled via RemoteFlight gauges</li>
                </ol>
            </>
        )
    } else if (os === 'windows' && sim === 'xplane') {
        return (
            <>
                <h4 className='supportSubHeading'>Running the server and connecting to the simulator</h4>
                <ol className='docsList'>
                    <li>Start X-Plane</li>
                    <li>Start RemoteFlight Server as administrator
                        <ul>
                            <li>On first run, the server will choose a random port, which you can randomize further or set manually. When you are happy with the port number, click the <strong>Start server</strong> button.</li>
                            <li>On first run a pop-up window will appear, requesting access to firewall if you have one enabled. Allow this exception so that RemoteFlight Server can be accessed via network.</li>
                            <li>If you are using any 3rd party firewall application, make sure it allows RemoteFlight Server (please consult the manual for your firewall on how to do this). I did testing with Norton and Avast firewall, both work without problems if properly set up.</li>
                            <li>Important note on the firewall setup: If your home network is configured as Public, make sure you allow connections to RemoteFlight server on Public networks.</li>
                            <li>Upon successful connection the status message in server window will change to <strong>Connected to FS</strong> and a message will appear in Flight Simulator.</li>
                        </ul>
                    </li>
                    <li>Your system is now ready to be controlled via RemoteFlight gauges</li>
                </ol>
            </>
        )
    } else if (os === 'macos') {
        return (
            <>
                <h4 className='supportSubHeading'>Running the server</h4>
                <ol className='docsList'>
                    <li>Start X-Plane and start a flight</li>
                    <li>Locate <strong>Plugins</strong> in the menu bar, choose <strong>RemoteFlight Server</strong> and choose <strong>Start Server</strong></li>
                    <li>You should hear a verbal confirmation that the server has started</li>
                    <li>Your system is now ready to be controlled via RemoteFlight gauges</li>
                </ol>
            </>
        )
    }
}

export function ConnectContent({sim, os}) {
    let ip = <li>RemoteFlight Server displays your PC local <strong>IP Address</strong> and <strong>Port</strong> numbers</li>
    if (os === 'macos') {
        ip = <li>On macOS, figure out your Mac`s IP adress:
            <ul>
                <li>Open the Apple menu and click System Settings</li>
                <li>Click Network in the left panel and then select Wi-Fi or Ethernet (for wired connections)</li> 
                <li>Click Details next to the network you're connected to</li>
                <li>Scroll down to see your Mac's local IP address.</li>
                <li>Use the default port of Mac plugin - <strong>1234</strong></li>
                <li>You can change port number by editing the ini file located in the plugin folder</li>
            </ul>
        </li>
    }
    
    return (
        <>
            <h4 className='supportSubHeading'>Connection settings in apps</h4>
            <ol className='docsList'>
                {ip}
                <li>Each RemoteFlight app has settings page, where you can enter the IP adress and Port</li>
                <li>Use <strong>Test Connection</strong> button to verify the connection works</li>
                <li>Save settings and enjoy the app!</li>
            </ol>
        </>
    )
    
}

export function RFTestResult() {
    return (
        <Stack sx={{display:'inline-block', background:'#111111', border:'1px solid #ffffff22', margin:'0.5rem 0 0.5rem 0',  borderRadius:'0.4rem', padding:'2rem'}} direction='column'>

            <div style={{fontSize:'1rem', fontWeight:'600'}}>Hello from RemoteFlight Server!</div>
            <div style={{marginTop:'2rem', fontSize:'0.8rem', fontWeight:'400', color: '#aaa'}}>
                If you see this message it means that:
                <ul>
                    <li style={{fontSize:'0.8rem', fontWeight:'400', color: '#aaa'}}>RemoteFlight Server is <span style={{color: '#33cc22'}}>running</span></li>
                    <li style={{fontSize:'0.8rem', fontWeight:'400', color: '#aaa'}}>RemoteFlight Server is connected to the simulator</li>
                    <li style={{fontSize:'0.8rem', fontWeight:'400', color: '#aaa'}}>RemoteFlight Server is <span style={{color: '#33cc22'}}>accessible</span> from 192.168.0.200:3385</li>
                </ul>
            </div>

        </Stack>
    )
}