import { Box, Grid, Link, Stack } from "@mui/material";
import Page from "./Page";
import DownloadButton from "../DownloadButton";
import { FileDownloadRounded, LinkRounded } from "@mui/icons-material";

export default function Server() {
    return (
        <Page>
            <Grid container columns={12} spacing={2} justifyContent='flex-start'>
                <Grid item xs={12} sm={12} md={6}>
                    <Box className='productDetailTile'>
                        <h2 className="tileHeading">Windows Downloads</h2>
                        <h3>RemoteFlight Server</h3>
                        <p>This free utility for Windows is required to establish the connection between the simulator and your device. RemoteFlight server is very lightweight and will not affect the performance.</p>
                        <p>Current server version: <strong>1.0.0.47</strong></p>
                        <Stack direction='column' spacing={1} sx={{marginBottom: '2rem'}}>
                            <DownloadButton link='/download/RemoteFlightServer.zip' icon={<FileDownloadRounded />} title='Get RemoteFlight Server'/>
                        </Stack>
                        <h3>Windows & Flight Simulator</h3>
                        <p>You will also need FSUIPC library for your version of the Flight Simulator. An unregistered version is sufficient to connect RemoteFlight Server to the sim.</p>
                        <Stack direction='column' spacing={1} sx={{marginBottom: '2rem'}}>
                            <DownloadButton link='http://fsuipc.com' icon={<LinkRounded />}title='Download FSUIPC'>FSUIPC Website</DownloadButton>
                        </Stack>
                        <h3>Windows + X-Plane</h3>
                        <p>When flying in X-Plane, you will need to install XPUIPC, which facilitates connection</p>
                        <Stack direction='column' spacing={1} sx={{marginBottom: '2rem'}}>
                            <DownloadButton link='http://www.schiratti.com/XPUIPCV2038.zip' icon={<LinkRounded />}title='Download XPUIPC'>FSUIPC Website</DownloadButton>
                        </Stack>
                    </Box>
                </Grid>

                <Grid item sx={12} sm={12} md={6}>
                    <Box className='productDetailTile'>
                        <h2 className="tileHeading">macOS Downloads</h2>
                        <p>On macOS we use native X-Plane plugin, so you only need to install this and no additional utilities are required. Please download the version based on your type of Mac or X-Plane.</p>
                        <h3>Apple Silicon chips</h3>
                        <p>All X-Plane versions with Apple M1, M2 and M3 chips</p>
                        <Stack direction='column' spacing={1} sx={{marginBottom: '2rem'}}>
                            <DownloadButton link='/download/rfxpl_m1.zip' icon={<FileDownloadRounded />} title='Download plugin'/>
                        </Stack>
                        <h3>Intel chips & X-Plane 11 and newer</h3>
                        <p>The plugin is compiled for MacOS X 32bit/64bit</p>
                        <Stack direction='column' spacing={1} sx={{marginBottom: '2rem'}}>
                            <DownloadButton link='/download/rfxpl_4_11.zip' icon={<FileDownloadRounded />} title='Download plugin'/>
                        </Stack>
                        <h3>Intel chips & X-Plane 10 or older</h3>
                        <p>The plugin is compiled for MacOS X 32bit/64bit</p>
                        <Stack direction='column' spacing={1} sx={{marginBottom: '2rem'}}>
                            <DownloadButton link='/download/rfxpl_4.zip' icon={<FileDownloadRounded />} title='Download plugin'/>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>

            <Box>
                <h4 className='productPageSubHeading'>Antivirus warning</h4>
                <Stack direction='column' spacing={1} alignItems='flex-start'>
                    <Box className='docsTile'>
                    <p><strong>Note:</strong> Some antivirus suites have been reported to have caused trouble for users trying to install Remote Flight Server. Disable your antivirus temporarily prior to installing RemoteFlight Server to avoid possible issues. More info is available in our FAQ.</p>
                    </Box>
                </Stack>
            </Box>

            <Box>
                <h4 className='productPageSubHeading'>RemoteFlight Server requirements</h4>
                <Stack direction='column' spacing={1} alignItems='flex-start'>
                    <Box className='docsTile'>
                        <ul className='docsList'>
                            <li>Microsoft Windows XP or later</li>
                            <li>Microsoft .NET Framework 4 ( Available <a href='http://www.microsoft.com/downloads/en/details.aspx?FamilyID=9cfb2d51-5ff4-4491-b0e5-b386f32c0992&displaylang=en'>here</a> or via Windows Update )</li>
                            <li>FSUIPC or XPUIPC installed for your version of the simulator</li>
                            <li>Unregistered version of FSUIPC is sufficient for RemoteFlight Server.</li>
                            <li>In case you have multiple simulator versions installed, make sure you install FSUIPC for all of them.</li>
                        </ul>
                    </Box>
                </Stack>
            </Box>

            <Box>
                <h4 className='productPageSubHeading'>Flight Simulator compatibility</h4>
                <Stack direction='column' spacing={1} alignItems='flex-start'>
                    <Box className='docsTile'>
                        <ul className='docsList'>
                            <li>Flight Simulator 2024</li>
                            <li>Flight Simulator 2020</li>
                            <li>Flight Simulator X SP2 or Acceleration</li>
                            <li>Flight Simulator X : Steam Edition</li>
                            <li>Flight Simulator 2004 - Century of Flight (SP 9.1)</li>
                            <li>Lockheed Martin Prepar3D</li>
                            <li>X-Plane 9,10,11,12 on Windows (XPUIPC plugin required)</li>
                            <li>X-Plane 9,10,11,12 on OS X/MacOS (see specific app compatibility panel for more info about X-Plane on MacOS)</li>
                        </ul>
                    </Box>
                </Stack>
            </Box>
        </Page>
    )
}