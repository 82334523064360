export function getProducts() {
    return (
          {
            glass:{
              id: 'glass',
              name: 'GLASS',
              link: '/remote-flight-glass',
              titleImage: 'title_image_GLASS2.png',
              productImage: 'product_GLASS.png',
              description: 'Enjoy a full-screen primary flight display (PFD), dual radios, adf and transponder, RMI and other features to complete your flight experience, all in one universal app. All in one app, crisp and clear across all devices.',
              productText: 'RemoteFlight GLASS is a glass cockpit extension app with everything you`ll ever need in your PFD app. Our first universal app for both iPad and iPhone in a single purchase. The app has a crisp and clear display accelerated with OpenGL rendering and runs in 60 fps.',
              appstoreLink: 'https://itunes.apple.com/us/app/remoteflight-glass/id1117951406',
              features:[
                'Attitude indicator',
                'Altitude indicator with vertical speed',
                'Airspeed indicator (+ ground speed info)',
                'Compass',
                'Altimeter (user selection knob)',
                'Slip indicator',
                'Localizer and glideslope',
                'Optional Wind indicator',
              ],
              RADIOS:[
                'Dual COM radio',
                'Dual NAV radio',
                'ADF radio',
                'XPDR setting'
              ],
              RMI: [
                'Compass',
                'NAV ID',
                'Beacon direction',
                'Beacon frequency',
                'OBS and localizer',
                'DME',
                'AP heading',
                'RMI display is optional'
              ],
              compatibility: [
               'Compatible with MSFS 2024 / MSFS 2020 / FSX / Prepar3D / FS9 / X-Plane 9, 10, 11 and 12 (both Windows and MacOS X)',
               'Works with all aircraft with radio - default or addon'
              ]
            },
            cockpit: {
              id: 'cockpit',
              name: 'COCKPIT HD',
              link: '/remote-flight-cockpit-hd',
              titleImage: 'title_image_COCKPITHD2.png',
              productImage: 'product_COCKPITHD.png',
              description: 'Full-featured, touch enabled cockpit panel replacement. Enjoy your scenery on full screen and let your iPad do the job displaying the gauges. Customize your cockpit according to your preference, either for pure VFR or hard-core IFR training.',
              productText: 'RemoteFlight COCKPIT HD is full-featured, touch enabled cockpit panel replacement for almost any of your Flight Simulator aircraft. Optimized for new iPad retina display from the very beginning but will look just as good on normal iPad screen. Enjoy your scenery on full screen and let your iPad do the job displaying the gauges.',
              appstoreLink: 'http://itunes.apple.com/app/remoteflight-cockpit-hd/id541400383',
              features: [
                'Pixel-perfect optimized animated gauges',
                'Touch controls specific to each gauge',
                'Cockpit layout is fully customizable',
                'Six basic instruments are included in basic package',
                'Advanced gauges are available as in-app-purchase',
                'Independent on which aircraft you are flying in sim',
                'Compatible with other RemoteFlight apps (e.g. Autopilot)',
                'Support for both landscape and portrait orientation'
              ],
              basicGauges: [
                {title: 'Airspeed Indicator', image: 'asi.png'},
                {title: 'Attitude Indicator', image: 'adi.png'},
                {title: 'Altimeter', image: 'alt.png'},
                {title: 'Turn Indicator', image: 'turn.png'},
                {title: 'Directional Gyro', image: 'hdg.png'},
                {title: 'Vertical Speed Indicator', image: 'vario.png'},
              ],
              advancedGauges: [
                {title: 'Simple Primary Flight Display', image: 'simple_efis.png'},
                {title: 'Cessna C172 Airspeed Indicator', image: 'asiC172.png'},
                {title: 'Beachcraft BE58 Airspeed Indicator', image: 'asiBE58.png'},
                {title: 'Robinson R22 Airspeed Indicator', image: 'asiR22.png'},
                {title: 'Electronic Altimeter', image: 'alt2.png'},
                {title: 'Turn Indicator', image: 'turn2.png'},
                {title: 'Attitude Indicator w. Slip', image: 'adi2.png'},
                {title: 'Horizontal Situation Indicator', image: 'hsi.png'},
                {title: 'VOR Indicator', image: 'vor.png'},
                {title: 'RPM Indicator', image: 'rpm.png'},
                {title: 'Robinson R22 Tachometer', image: 'rpmR22.png'},
                {title: 'Robinson R22 Manifold Pressure', image: 'manifoldR22.png'},
              ],
              compatibility: [
                'Overall compatible with MSFS 2024 / MSFS 2020 / FSX (SP2, FSX:SE) / Prepar3D / FS9 / X-Plane 9,10,11 and 12 (both Windows and MacOS X)',
                'Basic and simple gauges like airspeed, altimeters, gyro etc. will work with any aircraft',
                'HSI and VOR gauges may not work with PMDG products and other more advanced addons',
                'RPM gauge only works with piston propelled aircraft'
              ],
              reviews: [
                {stars: 5, country: 'UK', title:'Worth every penny!', text: 'I was at first skeptical on spending $17 on an iOS app. Never spent more than $5 on one (okay there was one time I spent $10 on XPlane 9 for my iPad). But man. This app is truly worth it. It connected to my FSX Acceleration and displayed flight details with nary a delay. And the pointer smoothness. Wow. You have to see it to believe it. A true FSX Add-On. I highly recommend to any FSX fan who owns an iPad.'},
                {stars: 5, country: 'DE', title:'Best purchase for FSX', text: 'This tool is outstanding! Wanted to expand my 3 display setup in FSX to at least 4 monitors, but realized that fps would go down dramatically (computer overclocked to 4.4 GHz, but very demanding add-ons.....). With this app I now train IR procedures without ANY loss of fps and still enjoy to look out of the window on a 5760 x 1080 screen. I used to have those small Saitek screens in the past, but the iPad replaced them completely!'},
                {stars: 5, country: 'DE', title:'Outstanding', text: 'What an amazing app! I run Lockheed Martin`s Prepar3d flight simulator and this app works a treat! Fully integrated into the program with easy installation. I dare say it runs smoother than the Saitek Flight Instrument panels. I recently upgraded the meters to all that inputwish offer. Did a test landing via ILS perfectly without autopilot. Inputwish have won themselves a lifelong customer!'}
              ]
            },
            maphd:{
              id: 'maphd',
              name: 'MAP HD',
              link: '/remote-flight-map-hd',
              appstoreLink: 'https://apps.apple.com/us/app/remoteflight-map-hd/id500492795',
              titleImage: 'title_image_MAPHD2.png',
              productImage: 'product_MAPHD.png',
              description: 'Explore the world while you fly. Map HD is a full-featured moving map for your iPad, with key flight information displayed along the route.',
              productText: 'RemoteFlight MAP HD is an ideal companion for long haul flights, as well as a tool for those, who like to explore the world while flying. Dragable and zoomable vector map moves with the aircraft and key flight variables are displayed on side-panel, which you can hide. You can choose from different types of maps, aircraft icons and units of measurement. Now you can sit back and relax, or chat with a stewardess, while still fully aware of the situation in cockpit thanks to iPad with RemoteFlight MAP HD.',
              features: [
                'Fast updates of flight variables',
                'Fullscreen map mode with minimized panel',
                'Configurable aircraft icons - 6 different aircraft types',
                'Configurable units - feet/knots or metres/kph',
                'Aerial map smoothly animated as you fly',
                'Optional vector map display from Google or Apple',
                'Display any of your FlightSimulator flight plan on the map',
                'In flight plan, display info on each waypoint as you fly',
              ],
              planes: [
                {title: 'Airbus A319', image: 'pl_a319.png'},
                {title: 'Boeing B737-800', image: 'pl_b737.png'},
                {title: 'Boeing B737-400', image: 'pl_b747.png'},
                {title: 'Beachcraft BE58', image: 'pl_beach.png'},
                {title: 'Bell Jet Ranger', image: 'pl_bell7.png'},
                {title: 'Cessna C172', image: 'pl_cessna.png'},
                {title: 'CRJ 200', image: 'pl_crj.png'},
                {title: 'Generic Arrow', image: 'pl_arrow.png'},
              ],
              reviews: [
                {stars: 5, title:'Great app for FSX', text:'This is a great add on to FSX allowing you to view exactly where you are anywhere in the world while flying Easy to install and set up and it`s so much better than using the on board gps that comes default with FSX, only wish I had download it sooner.!'},
                {stars: 5, title:'Great app!', text: 'All around great app! The moving map is nice. I really love how I can watch my aircraft taxi around airports using the google map/earth layout. Had a problem activating my flight plans but the author provided a fix immediately. Impressed with his great service. Well worth the purchase!'},
              ],
              compatibility: [
                'MSFS 2024 / MSFS 2020 / FSX / Prepar3D / FS9 / X-Plane 9, 10, 11 and 12 (both Windows and MacOS X)',
                'Works with all aircraft, helicopters, gliders, anything that moves',
                'For X-Plane flight plans must still be in MSFS format. This feature only works on Windows with RemoteFlight Server',
                
              ]
              
            },
            radio:{
              id: 'radio',
              name: 'RADIO HD',
              link: '/remote-flight-radio-hd',
              appstoreLink: 'https://apps.apple.com/us/app/remoteflight-radio-hd/id511250097',
              titleImage: 'title_image_RADIOHD2.png',
              productImage: 'product_RADIOHD.png',
              description: 'Level up your flight sim! All essential radio gauges, beautifully rendered & animated, at your fingertips. Switch layouts & use multi-touch for ultimate control. IFR training or airliner flights, RADIO HD is your copilot.',
              productText: 'RemoteFlight RADIO HD is the ultimate radio panel for iPad. Period. All your favourite radio gauges in one place, beautifully drawn, animated and programmed with pilot`s comfort in mind. Since all gauges don`t fit on one screen you can now switch between different gauge layouts, and instantly get the instrument you need. Use multi-touch for more precision or faster scrolling (knob turning) through frequencies. RemoteFlight RADIO HD aren`t just our iPhone apps packed together into one. Enjoy it, whether flying online in an airliner, or in your IFR training with smaller aircraft.',
              features: [
                'Altogether six gauges in one!',
                'COM1 / COM2 radio',
                'NAV1 / NAV2 radio',
                'ADF 1 and separate ADF2 tuner',
                'DME panel for both NAV frequencies',
                'Autopilot panel with heading selector',
                'Simple transponder panel',
                'Fast layout switching between gauges',
                'Syncing values to and from the simulator'
              ]
            },
            trq:{
              id: 'trq',
              name: 'TRQ', 
              link: '/remote-flight-trq', 
              appstoreLink: 'http://itunes.apple.com/app/remoteflight-trq/id1520422689',
              titleImage: 'title_image_TRQ2.png',
              productImage: 'product_trq.png',
              description: 'Convenient backup instrument for real-world aviation adventures. Configurable to display units of your choice - knots, kph, miles, meters, feet.',
              productText: 'Conveniently track your GPS heading, speed and altitude. The app works independently on your iPhone for devices with GPS and barometer. It is good to have that one additional backup system on board, whenever you need it.',
              featuresWithHeadings: [
                {
                  heading:'Configurable units of speed',
                  items: ['kilometers per hour', 'knots', 'miles per hour','meters per second']
                },
                {
                  heading:'Configurable units of altitude',
                  items: ['meters', 'feet']
                }
              ]
            },
            autopilot:{
              id: 'autopilot',
              name: 'AUTOPILOT',
              link: '/remote-flight-autopilot',
              appstoreLink: 'https://apps.apple.com/us/app/remoteflight-autopilot/id452544816',
              titleImage: 'title_image_AP.png',
              productImage: 'product_AP.png',
              description: 'Upgrade your cockpit with this feature-packed gauge. Replaces the boring panel with HDG, VS, NAV, APR and more!  Experience classic Bendix/King functionality with some helpful extras for smoother flying.',
              productText: 'The title says it all, this gauge replaces generic autopilot panel and delivers features like HDG, VS, NAV, APR. All standard autopilot functions from FS9 and FSX default aircafts are available in this gauge. We`ve even added some features that are not present on classic Bendix/Kind, but we thought would be useful to be able to control heading or NAV/GPS slaving from this panel.',
              features: [
                'Complete default autopilot replacement',
                'Heading and altitude hold',
                'Climb/descent with vertical speed hold',
                'Approach / NAV hold',
                'Approach works with VOR or ILS (with glideslope)',
                'Additional NAV / GPS slaving switch',
                'Additional setting of HDG hold direction',
              ],
              compatibility: [
                'Overall compatible with MSFS 2024 / MSFS 2020 / FSX (SP2, FSX:SE) / Prepar3D / FS9 / X-Plane 9,10,11 and 12 (both Windows and MacOS X)',
                'Works with all default aircraft with autopilot',
                'Limited functionality with advanced addon aircrafts with custom autopilot, especially airliners such as PMDG.'
              ],
              reviews: [
                {stars: 5, title:'Great app for FSX', text:'Very usefull tool for the FS, especially if you still wanna have control when your not next to your computer. The RemoteFlight Map is also very useful!'},
                {stars: 5, title:'Great app!', text: 'This is a great app for all fsx users!!!! I had a little conflict with my antivirus program but thanks to the great and fast feedback support from the builder of this app I had this running in no time!!! Thx again for helping!!!:))'},
              ],
            },
            map:{
              id: 'map',
              name: 'MAP',
              link: '/remote-flight-map',
              appstoreLink: 'http://itunes.apple.com/app/remoteflight-map/id446795954',
              titleImage: 'title_image_MAP.png',
              productImage: 'product_MAP.png',
              description: 'Take your flight sim experience to new heights with RemoteFlight MAP. Explore the world or conquer long hauls with a zoomable map that moves with you, and all key flight data at a glance. Choose your map style, aircraft icon, and units. Focus on the view, RemoteFlight MAP keeps you in control. (For iPhone)',
              productText: 'RemoteFlight MAP is an ideal companion for long haul flights, as well as a tool for those, who like to explore the world while flying. Dragable and zoomable vector map moves with the aircraft and key flight variables are displayed on side-panel, which you can hide. You can choose from different types of maps, aircraft icons and units of measurement. Now you can sit back and relax, or chat with a stewardess, while still fully aware of the situation in cockpit thanks to iPhone with RemoteFlight MAP.',
              features: [
                'Fast updates of flight variables',
                'Fullscreen map mode with minimized panel',
                'Configurable aircraft icons - 4 different aircraft types',
                'Configurable units - feet/knots or metres/kph',
                'HD vector map display from OpenStreet',
                '[Optional] Your FlightSimulator flight plans on the map',
                'In flight plan, display info on each waypoint as you fly'
              ],
              compatibility: [
                'MSFS 2024 / MSFS 2020 / FSX / Prepar3D / FS9 / X-Plane 9, 10, 11 amd 12 (both Windows and MacOS X)',
                'For X-Plane flight plans must still be in MSFS format and only work on Windows with RemoteFlight Server',
                'Works with all aircraft, helicopters, gliders, anything that moves',
                'An active internet connection is required to load the map tiles'
              ],
              reviews: [
                {stars: 5, title:'Perfect moving map!', text:'I did the installation as instructed, got the connection to the Flight Simulator and enjoyed following my flight through my iPod Touch. The fllight readings are bright and useful. The map zoom also works well in fine adjustable steps.'},
                {stars: 5, title:'Great tool!', text: 'A perfect addon to enhance realism and convience! I can be eating lunch in another room while still keeping tabs on my flight in FSX! A must have!'},
              ]
            },
            nav:{
              id: 'nav',
              name: 'NAV',
              titleImage: 'title_image_NAV.png',
              productImage: 'product_NAV.png',
              link: '/remote-flight-nav',
              appstoreLink: 'https://apps.apple.com/us/app/remoteflight-nav/id448043589',
              description: 'Take flight navigation to new heights with RemoteFlight NAV Panel! Control your VOR/DME & ADF radios, see DME data, and enjoy an intuitive interface. Works like our COMM Panel, but with more power for any aircraft.',
              productText: 'RemoteFlight NAV is just as straigtforward as COMM gauge, but slightly more sophisticated. Designed to replace common radio navigation panel NAV will connect to your Flight Simulator and allow you to control your navigation radio stations with your iPhone. See help below, as this gauge is a notch more complicated and a little hint is required.',
              features: [
                'Connects smoothly to your Flight Simulator',
                'Synchronizes with Flight Simulator radios when switched',
                'Works just like the real thing - just turn the knobs and push buttons',
                'Fully dual VOR/DME/ILS radio',
                'DME display with speed and time to station',
                'NDB tuner - full support for extended frequencies',
              ]
            },
            comm:{
              id: 'comm',
              name: 'COMM',
              titleImage: 'title_image_COMM.png',
              productImage: 'product_COMM.png',
              link: '/remote-flight-comm',
              appstoreLink: 'https://apps.apple.com/fr/app/remoteflight-comm/id445222883',
              description: 'RemoteFlight COMM turns your iPhone into a flight simulator communication control center. Easy to use, works with any aircraft, keeps radio frequencies at your fingertips. Fly smarter, fly with RemoteFlight COMM.',
              productText: 'RemoteFlight COMM is our first gauge in line, showcasing the basic ability of the concept and RemoteFlight ecosystem. It replaces the radio panel of any Flight Simulator aircraft, and enables you to control communication radio using your iPhone or other iOS compatible device. This is really a basic gauge but delivers with greatness. There`s nothing like having this crucial instrument right by the grasp of your fingers and always in sight.',
              features: [
                'Connects smoothly to your Flight Simulator',
                'Synchronizes with Flight Simulator radios instantly',
                'Works just like the real thing',
                'Turn the bigger wheel knob to change whole numbers',
                'Turn the smaller wheel knob to adjust decimals',
                'Press swap button to send the change to Flight Simulator',
                'Compatible with all aircrafts with a comm radio',
                'Perfect for online flying on VATSIM or IVAO'
              ],
              compatibility: [
                'Compatible with MSFS 2024 / MSFS 2020 / FSX / Prepar3D / FS9 / X-Plane 9, 10, 11 and 12 (both Windows and MacOS X)',
                'Works with all aircraft with radio - default or addon'
              ],
              reviews: [
                {stars: 5, title:'Works like a charm', text:'Ever since I received my iPhone I was constantly trying to look for new ways to have something from Flight Simulator X on my iPhone, I first tried a monitor extender application but that didn`t work so well, I then tried an application that sent altitude and heading information over the Internet but that was all very delayed. Then I found exactly what I was looking for, RemoteFlight! I opted for the Comm application and wow, I sure am impressed. This application has great Retina display functionality, the graphics are very crisp and clear. The ability to send the data over a local network is second to none and something previous apps have lacked, and best of all there is no delay at all! If I had to pick an improvement it would have to be the ability to see live standby frequency changes, at the moment you only change the standby frequency on the app and it pushes it into active in the sim when you press the swap button.'},
                {stars: 5, title:'User from Spain', text: 'It works very well, it`s an excellent application.'},
                {stars: 5, title:'User from Switzerland', text: 'Einfach gut. Integriert sich super, funktioniert reibungslos, und ist sehr nützlich. Danke'},
              ]
            },
        }
    )
}